import React from "react";
import * as Sentry from "@sentry/react";

// Doesn't require router or themes, ugly.
export const BasicErrorBoundary: React.FC = ({ children }) => (
  <Sentry.ErrorBoundary
    fallback={<p>You've encountered an error.</p>}
    showDialog={false}
  >
    {children}
  </Sentry.ErrorBoundary>
);
