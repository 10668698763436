import defaultMuiTheme from "./default-mui-theme";
import ITheme from "./ITheme";

const theme: ITheme = {
  ...defaultMuiTheme,
  palette: {
    ...defaultMuiTheme.palette,
    primary: {
      main: "#6A6E87",
      light: "rgb(135, 139, 159)",
      dark: "rgb(74, 77, 94)",
      contrastText: "#fff",
    },
    secondary: {
      main: "#BF2A7F",
      light: "rgb(203, 84, 152)",
      dark: "rgb(133, 29, 88)",
      contrastText: "#fff",
    },
    error: {
      main: "#DB6068",
      light: "#fbbdc2",
      dark: "rgb(153, 67, 72)",
      contrastText: "#fff",
    },
  },
  typography: {
    ...defaultMuiTheme.typography,
    fontFamily: '"Dosis", Arial, "Noto Sans", sans-serif, "Apple Color Emoji"',
  },
  // `overrides` typed as `any`, so don't expect any type hints
  overrides: {
    MuiCard: {
      root: {
        boxShadow: defaultMuiTheme.shadows["2"],
      },
    },
    MuiDialogTitle: {
      root: {
        backgroundColor: defaultMuiTheme.palette.grey["300"],
        "& h2": {
          color: defaultMuiTheme.palette.grey["800"],
          textAlign: "left",
          fontWeight: 300,
        },
      },
    },
    MuiToolbar: {
      regular: {
        padding: "0px 24px 0px",
      },
    },
    MuiDialogContent: {
      dividers: {
        borderTop: "1px solid" + defaultMuiTheme.palette.grey["500"],
        borderBottom: "none",
      },
    },
  },
};

// Not really sure where to put this to share it? Doesn't really fit in theme.palette
// TODO: Use references to the theme, and/or change these colors altogether
const chartStyles = {
  bar: {
    primary: "rgba(3, 121, 197, 1)",
    success: "#61BA96",
    error: theme.palette.error.main,
    hover: "#ff9948",
    hoverLight: "#FFE2C8",
    fill: "#EBEDEF",
    label: "#000000",
    annotationBorder: "#000000",
  },
  line: {
    primaryBorder: "rgba(3, 121, 197, 1)",
    primaryBackground: "rgba(3, 121, 197, 0.2)",

    primaryPointBorder: "#FFFFFF",
    primaryPointBackground: "rgba(3, 121, 197, 1)",

    primaryPointHoverBorder: "rgba(3, 121, 197, 1)",
    primaryPointHoverBackground: "#FFFFFF",

    secondaryBorder: "rgba(50, 52, 63, 1)",
    secondaryBackground: "rgba(50, 52, 63, 0.2)", // "#32343f"

    secondaryPointBorder: "#FFFFFF",
    secondaryPointBackground: "rgba(50, 52, 63, 1)",

    secondaryPointHoverBorder: "rgba(50, 52, 63, 1)",
    secondaryPointHoverBackground: "#FFFFFF",
  },
};

export { theme, chartStyles };
