export const FONT_SIZE = 14;
export const HTML_FONT_SIZE = 16;

export const EASING = {
  // This is the most common easing curve.
  easeInOut: "cubic-bezier(0.4, 0, 0.2, 1)",
  // Objects enter the screen at full velocity from off-screen and
  // slowly decelerate to a resting point.
  easeOut: "cubic-bezier(0.0, 0, 0.2, 1)",
  // Objects leave the screen at full velocity. They do not decelerate when off-screen.
  easeIn: "cubic-bezier(0.4, 0, 1, 1)",
  // The sharp curve is used by objects that may return to the screen at any time.
  sharp: "cubic-bezier(0.4, 0, 0.6, 1)",
};

// Follow https://material.io/guidelines/motion/duration-easing.html#duration-easing-common-durations
// to learn when use what timing
export const DURATION = {
  shortest: 150,
  shorter: 200,
  short: 250,
  // most basic recommended timing
  standard: 300,
  // this is to be used in complex animations
  complex: 375,
  // recommended when something is entering screen
  enteringScreen: 225,
  // recommended when something is leaving screen
  leavingScreen: 195,
};

export const BREAKPOINTS = {
  xs: 0, // phone
  sm: 600, // tablet
  md: 900, // small laptop
  lg: 1200, // desktop
  xl: 1536, // large screen
};

export const isString = (value: unknown): boolean => typeof value === "string";

export const isNumber = (value: string | number): boolean =>
  !Number.isNaN(parseFloat(`${value}`));

export const formatMs = (milliseconds: number): string =>
  `${Math.round(milliseconds)}ms`;

export function getAutoHeightDuration(height: number): number {
  if (!height) {
    return 0;
  }

  const constant = height / 36;

  // https://www.wolframalpha.com/input/?i=(4+%2B+15+*+(x+%2F+36+)+**+0.25+%2B+(x+%2F+36)+%2F+5)+*+10
  return Math.round((4 + 15 * constant ** 0.25 + constant / 5) * 10);
}

function createUnarySpacing(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  theme?: any
): ((abs: number) => number) | (() => undefined) {
  const themeSpacing = theme?.spacing || 8;

  if (typeof themeSpacing === "number") {
    return (abs: number) => {
      if (process.env.NODE_ENV !== "production") {
        if (typeof abs !== "number") {
          console.error(
            `Material-UI: Expected spacing argument to be a number, got ${abs}.`
          );
        }
      }
      return themeSpacing * abs;
    };
  }

  if (Array.isArray(themeSpacing)) {
    return (abs: number) => {
      if (process.env.NODE_ENV !== "production") {
        if (abs > themeSpacing.length - 1) {
          console.error(
            [
              `Material-UI: The value provided (${abs}) overflows.`,
              `The supported values are: ${JSON.stringify(themeSpacing)}.`,
              `${abs} > ${
                themeSpacing.length - 1
              }, you need to add the missing values.`,
            ].join("\n")
          );
        }
      }

      return themeSpacing[abs];
    };
  }

  if (typeof themeSpacing === "function") {
    return themeSpacing;
  }

  if (process.env.NODE_ENV !== "production") {
    console.error(
      [
        `Material-UI: The \`theme.spacing\` value (${themeSpacing}) is invalid.`,
        "It should be a number, an array or a function.",
      ].join("\n")
    );
  }

  return () => undefined;
}

// Customized from node_modules/@material-ui/core/styles/createSpacing.js
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function createSpacing(...createSpacingArgs: any[]): any {
  const spacingInput =
    createSpacingArgs.length > 0 && createSpacingArgs[0] !== undefined
      ? createSpacingArgs[0]
      : 8;

  // Already transformed.
  // @ts-ignore trust me
  if (spacingInput.mui) {
    return spacingInput;
  } // Material Design layouts are visually balanced. Most measurements align to an 8dp grid applied, which aligns both spacing and the overall layout.
  // Smaller components, such as icons and type, can align to a 4dp grid.
  // https://material.io/design/layout/understanding-layout.html#usage

  const transform = createUnarySpacing({
    spacing: spacingInput,
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const spacing = function spacing(...spacingArgs: any[]) {
    if (process.env.NODE_ENV !== "production") {
      if (!(spacingArgs.length <= 4)) {
        console.error(
          "Material-UI: Too many arguments provided, expected between 0 and 4, got ".concat(
            spacingArgs.length.toString()
          )
        );
      }
    }

    if (spacingArgs.length === 0) {
      return transform(1);
    }

    if (spacingArgs.length === 1) {
      return transform(spacingArgs[0]);
    }

    return spacingArgs
      .map(function (arg) {
        if (typeof arg === "string") {
          return arg;
        }

        const output = transform(arg);
        return typeof output === "number"
          ? "".concat(output.toString(), "px")
          : output;
      })
      .join(" ");
  };

  Object.defineProperty(spacing, "unit", {
    get: function get() {
      return spacingInput;
    },
  });
  spacing.mui = true;
  return spacing;
}
