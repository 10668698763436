import ITheme, { Breakpoint } from "./ITheme";
import { createSpacing } from "./theme-helpers";
import {
  BREAKPOINTS,
  DURATION,
  EASING,
  FONT_SIZE,
  HTML_FONT_SIZE,
  formatMs,
  isNumber,
  isString,
  getAutoHeightDuration,
} from "./theme-helpers";

const defaultMuiTheme: ITheme = {
  breakpoints: {
    keys: ["xs", "sm", "md", "lg", "xl"],
    values: {
      xs: BREAKPOINTS.xs,
      sm: BREAKPOINTS.sm,
      md: BREAKPOINTS.md,
      lg: BREAKPOINTS.lg,
      xl: BREAKPOINTS.xl,
    },
    up: (key: Breakpoint | number): string => {
      const value: number = typeof key === "number" ? key : BREAKPOINTS[key];
      return `@media (min-width:${value}px)`;
    },
    down: (key: Breakpoint | number): string => {
      const value: number = typeof key === "number" ? key : BREAKPOINTS[key];
      return `@media (max-width:${value - 5 / 100}px)`;
    },
  },
  direction: "ltr",
  mixins: {
    toolbar: {
      minHeight: 56,
      "@media (min-width:0px) and (orientation: landscape)": {
        minHeight: 48,
      },
      "@media (min-width:600px)": {
        minHeight: 64,
      },
    },
  },
  overrides: {
    MuiCard: {
      root: {
        boxShadow:
          "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
      },
    },
    MuiDialogTitle: {
      root: {
        backgroundColor: "#e0e0e0",
        "& h2": {
          color: "#424242",
          textAlign: "left",
          fontWeight: 300,
        },
      },
    },
    MuiToolbar: {
      regular: {
        padding: "0px 24px 0px",
      },
    },
    MuiDialogContent: {
      dividers: {
        borderTop: "1px solid#9e9e9e",
        borderBottom: "none",
      },
    },
  },
  palette: {
    common: {
      black: "#000",
      white: "#fff",
    },
    type: "light",
    primary: {
      main: "#6A6E87",
      light: "rgb(135, 139, 159)",
      dark: "rgb(74, 77, 94)",
      contrastText: "#fff",
    },
    secondary: {
      main: "#BF2A7F",
      light: "rgb(203, 84, 152)",
      dark: "rgb(133, 29, 88)",
      contrastText: "#fff",
    },
    error: {
      main: "#DB6068",
      light: "#fbbdc2",
      dark: "rgb(153, 67, 72)",
      contrastText: "#fff",
    },
    warning: {
      light: "#ffb74d",
      main: "#ff9800",
      dark: "#f57c00",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    info: {
      light: "#64b5f6",
      main: "#2196f3",
      dark: "#1976d2",
      contrastText: "#fff",
    },
    success: {
      light: "#81c784",
      main: "#4caf50",
      dark: "#388e3c",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    grey: {
      "50": "#fafafa",
      "100": "#f5f5f5",
      "200": "#eeeeee",
      "300": "#e0e0e0",
      "400": "#bdbdbd",
      "500": "#9e9e9e",
      "600": "#757575",
      "700": "#616161",
      "800": "#424242",
      "900": "#212121",
      A100: "#d5d5d5",
      A200: "#aaaaaa",
      A400: "#303030",
      A700: "#616161",
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.54)",
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.38)",
    },
    divider: "rgba(0, 0, 0, 0.12)",
    background: {
      paper: "#fff",
      default: "#fafafa",
    },
    action: {
      active: "rgba(0, 0, 0, 0.54)",
      hover: "rgba(0, 0, 0, 0.04)",
      hoverOpacity: 0.04,
      selected: "rgba(0, 0, 0, 0.08)",
      selectedOpacity: 0.08,
      disabled: "rgba(0, 0, 0, 0.26)",
      disabledBackground: "rgba(0, 0, 0, 0.12)",
      disabledOpacity: 0.38,
      focus: "rgba(0, 0, 0, 0.12)",
      focusOpacity: 0.12,
      activatedOpacity: 0.12,
    },
    getContrastText: (_background: string): string => "#fff",
  },
  spacing: createSpacing(),
  props: {},
  shadows: [
    "none",
    "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
    "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
    "0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)",
    "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
    "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
    "0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)",
    "0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)",
    "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)",
    "0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)",
    "0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)",
    "0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)",
    "0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)",
    "0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)",
    "0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)",
    "0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)",
    "0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)",
    "0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)",
    "0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)",
    "0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)",
    "0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)",
    "0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)",
    "0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)",
    "0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)",
    "0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)",
  ],
  typography: {
    pxToRem: (size: number): string =>
      `${(size / HTML_FONT_SIZE) * (FONT_SIZE / 14)}rem`,
    fontFamily: '"Dosis", Arial, "Noto Sans", sans-serif, "Apple Color Emoji"',
    fontSize: FONT_SIZE,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontFamily:
        '"Dosis", Arial, "Noto Sans", sans-serif, "Apple Color Emoji"',
      fontWeight: 300,
      fontSize: "6rem",
      lineHeight: 1.167,
    },
    h2: {
      fontFamily:
        '"Dosis", Arial, "Noto Sans", sans-serif, "Apple Color Emoji"',
      fontWeight: 300,
      fontSize: "3.75rem",
      lineHeight: 1.2,
    },
    h3: {
      fontFamily:
        '"Dosis", Arial, "Noto Sans", sans-serif, "Apple Color Emoji"',
      fontWeight: 400,
      fontSize: "3rem",
      lineHeight: 1.167,
    },
    h4: {
      fontFamily:
        '"Dosis", Arial, "Noto Sans", sans-serif, "Apple Color Emoji"',
      fontWeight: 400,
      fontSize: "2.125rem",
      lineHeight: 1.235,
    },
    h5: {
      fontFamily:
        '"Dosis", Arial, "Noto Sans", sans-serif, "Apple Color Emoji"',
      fontWeight: 400,
      fontSize: "1.5rem",
      lineHeight: 1.334,
    },
    h6: {
      fontFamily:
        '"Dosis", Arial, "Noto Sans", sans-serif, "Apple Color Emoji"',
      fontWeight: 500,
      fontSize: "1.25rem",
      lineHeight: 1.6,
    },
    subtitle1: {
      fontFamily:
        '"Dosis", Arial, "Noto Sans", sans-serif, "Apple Color Emoji"',
      fontWeight: 400,
      fontSize: "1rem",
      lineHeight: 1.75,
    },
    subtitle2: {
      fontFamily:
        '"Dosis", Arial, "Noto Sans", sans-serif, "Apple Color Emoji"',
      fontWeight: 500,
      fontSize: "0.875rem",
      lineHeight: 1.57,
    },
    body1: {
      fontFamily:
        '"Dosis", Arial, "Noto Sans", sans-serif, "Apple Color Emoji"',
      fontWeight: 400,
      fontSize: "1rem",
      lineHeight: 1.5,
    },
    body2: {
      fontFamily:
        '"Dosis", Arial, "Noto Sans", sans-serif, "Apple Color Emoji"',
      fontWeight: 400,
      fontSize: "0.875rem",
      lineHeight: 1.43,
    },
    button: {
      fontFamily:
        '"Dosis", Arial, "Noto Sans", sans-serif, "Apple Color Emoji"',
      fontWeight: 500,
      fontSize: "0.875rem",
      lineHeight: 1.75,
      textTransform: "uppercase",
    },
    caption: {
      fontFamily:
        '"Dosis", Arial, "Noto Sans", sans-serif, "Apple Color Emoji"',
      fontWeight: 400,
      fontSize: "0.75rem",
      lineHeight: 1.66,
    },
    overline: {
      fontFamily:
        '"Dosis", Arial, "Noto Sans", sans-serif, "Apple Color Emoji"',
      fontWeight: 700,
      fontSize: "0.75rem",
      lineHeight: 2.66,
      textTransform: "uppercase",
    },
  },
  shape: {
    borderRadius: 8,
  },
  transitions: {
    easing: {
      easeInOut: "cubic-bezier(0.4, 0, 0.2, 1)",
      easeOut: "cubic-bezier(0.0, 0, 0.2, 1)",
      easeIn: "cubic-bezier(0.4, 0, 1, 1)",
      sharp: "cubic-bezier(0.4, 0, 0.6, 1)",
    },
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      standard: 300,
      complex: 375,
      enteringScreen: 225,
      leavingScreen: 195,
    },
    create(
      props: string | Array<string> = ["all"],
      options: {
        prop?: string;
        duration?: number | string;
        easing?: string;
        delay?: number | string;
      } = {}
    ): string {
      const {
        duration: durationOption = DURATION.standard,
        easing: easingOption = EASING.easeInOut,
        delay = 0,
        ...other
      } = options;

      if (!isString(props) && !Array.isArray(props)) {
        console.error(
          'Material-UI: argument "props" must be a string or Array.'
        );
      }
      if (!isNumber(durationOption) && !isString(durationOption)) {
        console.error(
          `Material-UI: argument "duration" must be a number or a string but found ${durationOption}.`
        );
      }
      if (!isString(easingOption)) {
        console.error('Material-UI: argument "easing" must be a string.');
      }
      if (!isNumber(delay) && !isString(delay)) {
        console.error(
          'Material-UI: argument "delay" must be a number or a string.'
        );
      }
      if (Object.keys(other).length !== 0) {
        console.error(
          `Material-UI: unrecognized argument(s) [${Object.keys(other).join(
            ","
          )}]`
        );
      }

      return (Array.isArray(props) ? props : [props])
        .map(
          (animatedProp) =>
            `${animatedProp} ${
              typeof durationOption === "string"
                ? durationOption
                : formatMs(durationOption)
            } ${easingOption} ${
              typeof delay === "string" ? delay : formatMs(delay)
            }`
        )
        .join(",");
    },
    getAutoHeightDuration,
  },
  zIndex: {
    mobileStepper: 1000,
    speedDial: 1050,
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
  },
};

export default defaultMuiTheme;
