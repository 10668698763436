/**
 * Constants for the current environment
 */
export const PRODUCTION = process.env.NODE_ENV === "production";
export const TEST = process.env.NODE_ENV === "test";

export const SPECIALIST_HOSTNAME = process.env.SPECIALIST_HOSTNAME;
export const ARACHNE_HOSTNAME = process.env.ARACHNE_HOSTNAME;
export const MIDAS_HOSTNAME = process.env.MIDAS_HOSTNAME;
export const PATIENT_PORTAL_HOSTNAME = process.env.PATIENT_PORTAL_HOSTNAME;
export const LOGIN_URL = `${ARACHNE_HOSTNAME}/auth`;
export const RESCREEN_AUTH_URL = `${ARACHNE_HOSTNAME}/auth_rescreen`;
export const ARACHNE_API_URL = `${ARACHNE_HOSTNAME}/graphql`;
export const ASCLEPEION_HOSTNAME = process.env.ASCLEPEION_HOSTNAME;
export const SENTRY_DSN =
  process.env.ITHACA_SENTRY_DSN || process.env.SENTRY_DSN;
export const SENTRY_SAMPLE_RATE = Number(
  process.env.ITHACA_SENTRY_SAMPLE_RATE || process.env.SENTRY_SAMPLE_RATE || 0
);
export const SEND_EVENTS_TO_SENTRY =
  process.env.SEND_EVENTS_TO_SENTRY === "true";
export const SENTRY_ENVIRONMENT = process.env.SENTRY_ENVIRONMENT;
export const SEND_EVENTS_TO_PENDO = process.env.SEND_EVENTS_TO_PENDO === "true";
export const PENDO_API_KEY = process.env.PENDO_API_KEY;
export const ZENDESK_API_KEY = process.env.ZENDESK_API_KEY;
export const POPULUS_PARTNER_NETWORK = process.env.POPULUS_PARTNER_NETWORK;
export const POPULUS_PARTNER_ID = process.env.POPULUS_PARTNER_ID;
export const POPULUS_PARTNER_ZONE = process.env.POPULUS_PARTNER_ZONE;
export const POPULUS_MIN_HEIGHT = 50;
export const POPULUS_MIN_WIDTH = 320;

export const STATE_ERROR_WHITELIST = ["authenticated", "error", "user"];

export enum UserPermission {
  CREATE_PATIENT = "create:patient",
  MANAGE_CARE_PLAN = "edit:careplan",
  VIEW_MANAGER_GRID = "view:manager",
  SWITCH_SYSTEM = "view:systemswitcher",
  ALLOW_VIEW_ENCOUNTERS = "view:encounters",
}
