import React, { useEffect } from "react";
import * as Sentry from "@sentry/react";
import styled from "styled-components";
import { FallbackRender } from "@sentry/react/dist/errorboundary";
import { Box, Paper, Typography } from "@material-ui/core";

import { theme } from "../theme";

const StyledSvg = styled.svg`
  max-width: 12em;
`;

const ErrorFallback = styled(Paper)`
  height: 100%;
  width: 30rem;
  margin: 0 auto;
`;

interface ISuspenseProps extends React.SuspenseProps {
  errorFallback?: JSX.Element | null;
}

export const Fallback: FallbackRender = ({ error }) => {
  useEffect(() => {
    if (error) {
      Sentry.captureException(error);
    }
  }, [error]);

  return (
    <ErrorFallback>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        padding="1.5em"
        textAlign="center"
        marginTop="0.5em"
      >
        <StyledSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 71 71">
          <path
            fill="#E9DBE0"
            d="M20.624 28.62v27.673h27.607c.908 0 1.645-.76 1.645-1.697v-10.65L20.624 28.62z"
          />
          <path
            fill="#E28BA6"
            d="M12.729 17.234l-2.018-.522.682-1.166-1.914-.214.262-1.438-1.816-.301.464-2.496 2.965 2.034-.412 1.448 1.825.296-.606 1.308z"
          />
          <g
            fill="none"
            stroke="#E28AA5"
            strokeLinecap="round"
            strokeMiterlimit="10"
          >
            <path d="M11.721 58.509l-2.646.424M10.518 59.903l-.283-2.664" />
          </g>
          <circle fill="#E28AA5" cx="57.282" cy="22.734" r=".569" />
          <circle fill="#E28AA5" cx="61.802" cy="31.708" r="1.274" />
          <path
            fill="none"
            stroke="#EADBDF"
            strokeLinecap="square"
            strokeLinejoin="bevel"
            strokeMiterlimit="10"
            strokeDasharray="3"
            d="M25.161 19.433h12.371M25.161 25.292h10.684"
          />
          <path
            fill="none"
            stroke="#FFF"
            strokeMiterlimit="10"
            d="M27.257 32.095h-3.096M24.161 37.329h13.083M42.417 39.946H24.161"
          />
          <path
            fill="none"
            stroke="#FFF"
            strokeLinecap="square"
            strokeLinejoin="bevel"
            strokeMiterlimit="10"
            d="M24.161 46.769h16.535M37.829 49.386H24.161"
          />
          <path
            fill="none"
            stroke="#FFF"
            strokeMiterlimit="10"
            d="M32.253 34.712h-8.092"
          />
          <path
            fill="none"
            stroke="#EADBDF"
            strokeLinecap="square"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeDasharray="3"
            d="M42.923 14.521v8.019h7.953"
          />
          <path
            fill="none"
            stroke="#EADBDF"
            strokeLinecap="square"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeDasharray="3"
            d="M38.244 33.327l12.632 6.618V22.228l-7.953-8.02H23.27c-.91 0-1.646.76-1.646 1.697v8.715l16.62 8.707z"
          />
          <path
            fill="none"
            stroke="#EADBDF"
            strokeLinecap="square"
            strokeLinejoin="bevel"
            strokeMiterlimit="10"
            strokeDasharray="3"
            d="M43.245 27.917h-15.16M38.743 33.329h9.002"
          />
          <g
            fill="none"
            stroke="#EADBDF"
            strokeLinecap="square"
            strokeLinejoin="bevel"
          >
            <path d="M43.747 30.683h-1.5" />
            <path strokeDasharray="2.3879,2.3879" d="M39.858 30.683h-3.582" />
            <path d="M35.083 30.683h-1.5" />
          </g>
          <path
            fill="none"
            stroke="#FFF"
            strokeMiterlimit="10"
            d="M49.876 50.339v-6.394L20.624 28.62v7.302"
          />
          <path
            fill="#E28BA6"
            d="M49.376 44.248v10.348c0 .66-.514 1.197-1.145 1.197H21.124V29.446l-1-1.652v28.999h28.107c1.183 0 2.145-.985 2.145-2.197V43.643l-1 .605z"
          />
          <path
            fill="none"
            stroke="#EADBDF"
            strokeLinecap="square"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            d="M50.876 39.479v.466L21.624 24.62v-.656"
          />
        </StyledSvg>
        {error.name === "ChunkLoadError" ? (
          <>
            <Typography variant="h5" color="primary">
              This application has been updated.
            </Typography>
            <Box mt="0.5em">
              <Typography>
                Please refresh your browser to see the latest content.
              </Typography>
            </Box>
          </>
        ) : (
          <Typography variant="h5" color="primary">
            It appears you've encountered an error.
          </Typography>
        )}
        <Box mt="0.5em">
          <Typography>
            An error report is on its way to us. If you have questions, please
            contact us at{" "}
            <a
              className="no-underline"
              style={{ color: theme.palette.primary.main }}
              href="mailto:support@canceriq.com?subject=Error Report&body=I'm having trouble with CancerIQ, would you mind taking a look?"
            >
              support@canceriq.com
            </a>
            . We're here to help!
          </Typography>
        </Box>
      </Box>
    </ErrorFallback>
  );
};

const Suspense: React.FC<ISuspenseProps> = (props) => (
  <Sentry.ErrorBoundary
    fallback={props.errorFallback ? props.errorFallback : Fallback}
    showDialog={false}
  >
    <React.Suspense {...props}>{props.children}</React.Suspense>
  </Sentry.ErrorBoundary>
);

export default Suspense;
