/**
 * Renders the App in the DOM and top-level imports
 */

// Polyfills
import "core-js/stable";
import "regenerator-runtime/runtime";
import "whatwg-fetch";

import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
// Must import sentry/tracing after sentry/react according to Sentry documentation
import { Integrations } from "@sentry/tracing";
import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";

import { BasicErrorBoundary } from "./context/error-boundary/basic-error-boundary";
import {
  SEND_EVENTS_TO_SENTRY,
  SENTRY_DSN,
  SENTRY_SAMPLE_RATE,
  SENTRY_ENVIRONMENT,
} from "./constants";

import Suspense from "./helpers/suspense";

const CernerSmartEmbeddableLibImport = React.lazy(
  () => import("./cerner-smart-embeddable-lib")
);

/**
 * Helper utility to check if the current browser is the embedded IE browser
 * control in Cerner PowerChart. Copied from https://github.com/cerner/fhir-client-cerner-additions/blob/main/src/js/utils.js#L14
 */
const isPowerChart = () =>
  window.external &&
  typeof (window.external as any).DiscernObjectFactory !== "undefined";

// eslint-disable-next-line @typescript-eslint/no-var-requires, @typescript-eslint/no-require-imports
const { name, version } = require("../package.json");
// Initialize Sentry SDK as early as possible
Sentry.init({
  dsn: SEND_EVENTS_TO_SENTRY ? SENTRY_DSN : undefined,
  integrations: [
    new CaptureConsoleIntegration({
      levels: ["error"],
    }),
    new Integrations.BrowserTracing({
      tracingOrigins: ["localhost", "api.canceriq.com"],
      // react-router integration
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      idleTimeout: 20000, // Wait 20s before marking transaction as complete
    }),
  ],
  normalizeDepth: 10,
  // Only send events in production
  tracesSampleRate: SENTRY_SAMPLE_RATE,
  environment: SENTRY_ENVIRONMENT,
  release: `${name}@${version}`,
});

const App = React.lazy(
  () => import(/* webpackPreload: true, webpackChunkName: 'app' */ "./app")
);

ReactDOM.render(
  <BasicErrorBoundary>
    <Suspense fallback={null}>
      {isPowerChart() && <CernerSmartEmbeddableLibImport />}
      <App />
    </Suspense>
  </BasicErrorBoundary>,
  document.getElementById("root")
);
